import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";

import { selectUserType } from "actions/userRegisterActions";

const SelectUserTypeCard = ({ selectUserType }) => {
  return (
    <Card className="card auth-card shadow-lg">
      <Card.Body className="card-body">
        <p style={{ fontSize: "18px", lineHeight: 1.5 }}>
          To Register for a new Compel account, please send an email requesting a new account to Cal at{" "}
          <a
            href="mailto:callahanp@liebermanresearch.com?subject=Compel New Account Request"
            style={{ fontFamily: "inherit", textDecoration: "underline", fontSize: "16px" }}
          >
            callahanp@liebermanresearch.com
          </a>
          .
        </p>
        <p style={{ fontSize: "18px", lineHeight: 1.5 }}>Thank you.</p>
        {/* <div className="px-3">
          <h2 className="text-center">You are a...</h2>
        </div>
        <Row>
          <Col className="mt-5">
            <Button onClick={() => selectUserType(1)} className="btn btn-primary btn-block" variant="primary" size="lg">
              Lieberman Employee
            </Button>
          </Col>
          <Col className="mt-5">
            <Button onClick={() => selectUserType(2)} className="btn btn-primary btn-block" variant="primary" size="lg">
              Lieberman Client
            </Button>
          </Col>
        </Row> */}
      </Card.Body>
    </Card>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ selectUserType }, dispatch);
};

export default connect(null, mapDispatchToProps)(SelectUserTypeCard);
